import { GetStaticProps } from 'next';
import dynamic from 'next/dynamic';
import { ReactElement } from 'react';

import { HorizontalDivider } from '@components/HorizontalDivider/HorizontalDivider';
import { HomepageLayout } from '@components/Layouts';
import { InformationPage } from '@interfaces/InformationPage';
import { FAQ } from '@interfaces/Sanity';
import {
  getActiveHomeCIs,
  getDraftHomeCIs,
  getHomePagePopularSearches,
} from '@lib/data/homePage';
import {
  getInformationPageDataById,
  getInformationPageDataUsingCache,
  getMetaData,
} from '@lib/data/informationPageData';
import { addBigCommerceDataToBaseProducts } from '@lib/data/productData';
import { convertFAQToStructedData } from '@lib/structuredData';
import { log, logBreadcrumb } from '@lib/utils';

import Page, { PageProps } from './[...page]';

// lazy load to prioritise carousel
const PopularSearchesDynamic = dynamic(() =>
  import('../components/PopularSearches/HomePagePopularSearches').then(
    (mod) => mod.PopularSearches
  )
);
// eslint-disable-next-line no-restricted-syntax
export default Page;

export const getStaticProps: GetStaticProps = async ({
  params,
  preview,
  previewData,
}) => {
  try {
    const popularSearches = await getHomePagePopularSearches();

    let pageData: InformationPage;

    if (preview) {
      // This is preview mode
      pageData = await getInformationPageDataById(
        (previewData as { id: string }).id
      );

      if (!pageData) {
        // fallback failed
        return {
          notFound: true,
        };
      }
    }

    pageData = await getInformationPageDataUsingCache('');

    if (!pageData) {
      // fallback failed
      return {
        notFound: true,
      };
    }

    const meta = await getMetaData(pageData._id);

    const labResults = [];

    const faqs = pageData.content
      ? (
          pageData.content
            .map((d) => d.contentBody)
            .flat()
            .filter((c) => c && c._type === 'faq') as FAQ[]
        ).map((faq) => ({
          question: faq.question,
          answer: faq.answer
            .map((a) => a.children.map((c) => c.text).join(' '))
            .join(' '),
        }))
      : [];

    const faqStructuredData = convertFAQToStructedData(faqs);

    /* Fetch the banner/hero CTA documents from Sanity */
    const homeCarouselItems = preview
      ? await getDraftHomeCIs()
      : await getActiveHomeCIs();

    // If there is a product carousel, add the BC data that is needed for a BaseProduct
    // Get all products out
    const products = [] as Array<any>;
    pageData.content.forEach((content) => {
      content.contentBody.forEach((block) => {
        if (block._type === 'carousel' && block.items[0]._type === 'product') {
          products.push(...block.items);
        }
      });
    });

    // Map products
    const mappedProducts = await addBigCommerceDataToBaseProducts(products);

    // Replace them
    pageData.content.forEach((content) => {
      content.contentBody = content.contentBody.map((block) => {
        if (block._type === 'carousel' && block.items[0]._type === 'product') {
          const mappedItems = block.items.map((item) =>
            mappedProducts.find((p) => p.entityId === item.entityId)
          );
          return {
            ...block,
            items: mappedItems,
          };
        }
        return block;
      });
    });

    return {
      props: {
        pageData,
        breadcrumbs: [],
        meta,
        labResults,
        faqStructuredData,
        homeCarouselItems,
        popularSearches,
        isPreview: !!preview,
      },
    };
  } catch (error) {
    logBreadcrumb({
      category: 'pageRenderError',
      message: 'Error in getStaticProps for home page',
      level: 'error',
    });
    log({ error });
    return {
      notFound: true,
    };
  }
};

Page.getLayout = function getLayout(page: ReactElement & { props: PageProps }) {
  return (
    <HomepageLayout>
      {page}
      <HorizontalDivider />
      {page.props.popularSearches && page.props.popularSearches.length > 0 && (
        <PopularSearchesDynamic links={page.props.popularSearches} />
      )}
    </HomepageLayout>
  );
};
